import { FC, useEffect, useState } from "react";
import { isDataValid } from "../../../../core/formValidation";
import { Contact, iContact } from "../../../../core/models/Contact";
import { IconDelete } from "../../../../icons";
import Button from "../../Button/Button";
import ConfirmPopup from "../../ConfirmPopup/ConfirmPopup";
import FormToggle from "../../FormToggle/FormToggle";
import InputField from "../../InputField/InputField";
import ContactsForm from "../ContactsForm";
import { iValidationData, validationConfig } from "./FormCustomContact.helpers";

interface iProps {
    isShown: boolean;
    contact?: iContact | null;
    onClose: () => void;
    onRemove: (id: number) => void;
    onSave: (item: iContact) => void;
}

const FormCustomContact: FC<iProps> = ({
    isShown,
    onClose,
    contact,
    onSave,
    onRemove,
}) => {
    const [isPublic, setIsPublic] = useState<boolean>(false);
    const [data, setData] = useState<iContact>(new Contact());
    const [isValidationShown, setIsValidationShown] = useState(false);
    const validationData: iValidationData = {};
    const isValid: boolean = isDataValid(validationData)(validationConfig);
    const isEdit = !!contact;
    const [isShownConfirmPopup, setIsShownConfirmPopup] = useState(false);

    const handleInputChange = (fieldName: keyof iContact) => (value: string) => {
        setData({ ...data, [fieldName]: value });
    };

    useEffect(() => {
        if (contact) {
            setData(contact);
        } else {
            setData(new Contact());
        }
    }, [contact]);

    const handleSave = () => {
        if (isValid) {
            setIsValidationShown(false);

            const transformedDataToBackend = (item: iContact): iContact => ({
                ...item,
                isCustom: true,
                isPublic
            });

            onSave(transformedDataToBackend(data));

            setData(new Contact());
        } else {
            setIsValidationShown(true);
        }
    };

    const handleRemoveContact = () => {
        setIsShownConfirmPopup(false);
        onRemove(data.id);
    };

    return (
        <ContactsForm isShown={isShown} onClose={onClose}>
            <div className="ContactsForm__header">
                <div className="ContactsForm__header-title">{isEdit ? "Edit" : "Add"} contact</div>

                <div className="ContactsForm__header-actions">
                    <FormToggle
                        labels={["For me", "For my company"]}
                        isChecked={isPublic}
                        onChange={() => setIsPublic(!isPublic)}
                    />
                </div>
            </div>

            <div className="ContactsForm__form">
                <div className="ContactsForm__row">
                    <InputField
                        label="Full name"
                        onChange={handleInputChange("name")}
                        value={data.name}
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Job Title"
                        onChange={handleInputChange("title")}
                        value={data.title}
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Email"
                        onChange={handleInputChange("email")}
                        value={data.email}
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Phone number"
                        onChange={handleInputChange("phone")}
                        value={data.phone}
                    />
                </div>
                <div className="ContactsForm__row">
                    <InputField
                        label="Linkedin profile link"
                        onChange={handleInputChange("linkedInProfile")}
                        value={data.linkedInProfile}
                    />
                </div>
            </div>

            <div className="ContactsForm__footer">
                {isEdit && (
                    <div className="ContactsForm__footer-action ContactsForm__footer-action--remove">
                        <Button
                            className="ContactsForm__footer-button ContactsForm__footer-button--remove"
                            onClick={() => setIsShownConfirmPopup(true)}
                            modifiers={["secondary"]}
                        >
                            <IconDelete width={16} height={20} />
                            Delete contact
                        </Button>
                    </div>

                )}
                <div className="ContactsForm__footer-action">
                    <Button
                        className="ContactsForm__footer-button ContactsForm__footer-button--close"
                        onClick={onClose}
                        modifiers={["secondary"]}
                    >
                        Cancel
                    </Button>
                </div>
                <div className="ContactsForm__footer-action">
                    <Button
                        className="ContactsForm__footer-button ContactsForm__footer-button--submit"
                        onClick={handleSave}
                        modifiers={["primary"]}
                    >
                        SAVE CONTACT
                    </Button>
                </div>
            </div>

            <ConfirmPopup
                isShown={isShownConfirmPopup}
                onClose={() => setIsShownConfirmPopup(false)}
                onSubmit={handleRemoveContact}
            >
                <div className="ContactsForm__confirm-message">
                    <div className="ContactsForm__confirm-icon">
                        <IconDelete width={32} height={38} />
                    </div>
                    Are you sure you want to delete contact <b>{data.name}</b>?
                </div>
            </ConfirmPopup>
        </ContactsForm>
    );
};

export default FormCustomContact;
