import React, { useMemo, useState } from "react";
import ActionButtons from "../../../../components/new/Table/ActionButtons/ActionButtons";
import Table from "../../../../components/new/Table/Table";
import { highlightValue, iTableConfigItem } from "@components/new/Table/Table.helpers";
import TableCellValue from "../../../../components/new/Table/TableCellValue";
import { TableContext } from "@components/new/Table/TableContext";
import TableHead from "../../../../components/new/Table/TableHead";
import useTableSort from "../../../../components/new/Table/useTableSort";
import { iContact } from "@models/Contact";
import {
    IconEditWhite,
    IconLinkedinSimple,
    IconMenu,
} from "@icons";
import { ContactsTypeEnum } from "./Contacts.helpers";
import "./ContactsList.styles.scss";
import { CopyButtonContext } from "@components/new/Table/CopyButton";

interface iProps {
    data: iContact[];
    searchQuery: string;
    onEdit: (id: number, isCustom: boolean) => void;
    onAdd: () => void;
    chainLogo: string;
    contactsType: ContactsTypeEnum;
}

const MAX_CONTACTS_NUMBER_TO_SHOW = 10;

const ContactsList: React.FC<iProps> = ({
    data: rawData = [],
    onEdit,
    searchQuery,
    chainLogo,
    onAdd,
    contactsType,
}) => {
    const isCustom = !(contactsType === ContactsTypeEnum.Firefly);
    const [isExpanded, setIsExpanded] = useState(false);
    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortedData,
    } = useTableSort(rawData, "name");

    const tableData = useMemo(
        () =>
            isExpanded
                ? sortedData
                : sortedData.slice(0, MAX_CONTACTS_NUMBER_TO_SHOW),
        [isExpanded, sortedData],
    );

    const tableConfig: iTableConfigItem[] = useMemo(
        () => {
            const columnIcon = (field: keyof iContact) => getColumnIcon(field, sortingField, sortingDirection);
            return [
                {
                    title: "Name",
                    icon: columnIcon("name"),
                    handleHeaderClick: handleSort("name"),
                    isActive: sortingField === "name",
                    Value: ({ index }) => {
                        return (
                            <TableCellValue isWrap>
                                <span className="ContactsList__cell-name">
                                    {highlightValue(tableData[index].name.toUpperCase(), searchQuery)}
                                </span>
                            </TableCellValue>
                        );
                    },
                },
                {
                    title: "Job Title",
                    icon: columnIcon("title"),
                    handleHeaderClick: handleSort("title"),
                    isActive: sortingField === "title",
                    width: "20%",
                    Value: ({ index }) => (
                        <TableCellValue>
                            {highlightValue(tableData[index].title.toUpperCase(), searchQuery)}
                        </TableCellValue>
                    ),
                },
                {
                    title: "Email",
                    width: "20%",
                    icon: columnIcon("email"),
                    handleHeaderClick: handleSort("email"),
                    isActive: sortingField === "email",
                    Value: ({ index }) => (
                        <TableCellValue>{tableData[index].email || "-"}</TableCellValue>
                    ),
                },
                {
                    title: "Phone",
                    icon: columnIcon("phone"),
                    handleHeaderClick: handleSort("phone"),
                    isActive: sortingField === "phone",
                    width: "15%",
                    Value: ({ index }) => (
                        <TableCellValue>{tableData[index].phone || "-"}</TableCellValue>
                    ),
                },
                {
                    title: "",
                    width: 75,
                    Value: ({ index }) => (
                        <>
                            {tableData[index].linkedInProfile && (
                                <a
                                    className="ContactsList__table-social-icon"
                                    href={tableData[index].linkedInProfile}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <IconLinkedinSimple/>
                                </a>
                            )}
                        </>
                    ),
                },
                {
                    title: "",
                    width: 50,
                    Value: ({ index }) => {
                        const item = tableData[index];
                        const actions = [];
                        if (item.canEdit || !item.isCustom) {
                            actions.push({
                                onClick: () => onEdit(item.id, item.isCustom),
                                Icon: item.isCustom ? IconEditWhite : IconMenu,
                            });
                        }
                        return (
                            <ActionButtons
                                style={{ paddingRight: 10 }}
                                config={actions}
                            />
                        );
                    },
                },
            ]
        },
        [chainLogo, getColumnIcon, handleSort, isCustom, onEdit, searchQuery, sortingDirection, sortingField, tableData],
    );

    const getClipboardData = useMemo(() => ({ head, data }: { head: string[], data: iContact[] }) => {
        return [
            head,
            ...data.map(item => [
                item.name,
                item.title,
                item.email,
                item.phone.replace(/\+/g, ""),
                item.linkedInProfile,
            ]),
        ]
    }, []);

    return (
        <div className="ContactsList">
            <div className="ContactsList__table">
                <TableContext.Provider value={{ config: tableConfig }}>
                    {rawData.length > 0 && (
                        <TableHead styles={{ paddingRight: 30 }}/>
                    )}
                </TableContext.Provider>
                <div className="ContactsList__add-panel">
                    <div className="ContactsList__add" onClick={onAdd}>
                        {isCustom ? "+ add my contact" : "+ request addition"}
                    </div>
                </div>
                <CopyButtonContext.Provider value={{
                    data: getClipboardData({
                        head: tableConfig.map(item => item.title || ""),
                        data: sortedData,
                    })
                }}>
                    <Table
                        isLoading={false}
                        config={tableConfig}
                        rowsCount={tableData.length}
                        hasHeader={false}
                        hasInnerScroll={false}
                    />
                </CopyButtonContext.Provider>
                {rawData.length > MAX_CONTACTS_NUMBER_TO_SHOW && (
                    <div
                        className="ContactsTiles__toggle"
                        onClick={() => setIsExpanded(!isExpanded)}
                        onKeyDown={() => null}
                    >
                        {isExpanded ? "View less" : "View more"}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ContactsList;
