import { iFormConfigItem, isEmail, isNotEmpty } from "@core/formValidation";

export interface iValidationAddData {
    name: string;
    department: string;
    email: string;
    title: string;
}

export const validationAddConfig: iFormConfigItem<iValidationAddData>[] = [
    { field: "name", validationCheckers: [isNotEmpty] },
    { field: "title", validationCheckers: [isNotEmpty] },
    { field: "email", validationCheckers: [isNotEmpty, isEmail] },
];

export interface iValidationUpdateData {
    message: string;
}

export const validationUpdateConfig: iFormConfigItem<iValidationUpdateData>[] = [
    { field: "message", validationCheckers: [isNotEmpty] }
];