import { FC, useEffect, useState } from "react";
import { getInputErrors, isDataValid } from "@core/formValidation";
import { iContact } from "@models/Contact";
import Button from "../../../Button/Button";
import ContactCard from "../../../ContactCard/ContactCard";
import Textarea from "../../../Textarea/Textarea";
import { iValidationUpdateData, validationUpdateConfig } from "../FormRequestContact.helpers";

interface iProps {
    onClose: () => void;
    onSubmit: (item: any) => void;
    isShown?: boolean;
    contact: iContact | null;
}
const FormUpdate: FC<iProps> = ({ onClose, onSubmit, isShown, contact }) => {
    const [isValidationShown, setIsValidationShown] = useState(false);
    const [message, setMessage] = useState("");
    const validationUpdateData: iValidationUpdateData = {
        message,
    };

    const isValid = isDataValid(validationUpdateData)(validationUpdateConfig);

    const fieldHasError = (field: keyof iValidationUpdateData) =>
        !!getInputErrors(validationUpdateData)(validationUpdateConfig)(field).length &&
        isValidationShown;

    const handleSave = () => {
        if (isValid) {
            setIsValidationShown(false);
            onSubmit({
                message,
                contact,
            });
        } else {
            setIsValidationShown(true);
        }
    };

    useEffect(() => {
        return () => {
            setIsValidationShown(false);
            setMessage("");
        };
    }, []);

    if (!isShown) return null;

    return (
        <>
            <div className="ContactsForm__form">
                <div className="ContactsForm__row has-space">
                    {contact && (
                        <ContactCard data={contact} onEdit={() => {}} isPreview />
                    )}

                    <Textarea
                        onChange={setMessage}
                        value={message}
                        placeholder="Describe what you want to update in contacs"
                        maxHeight={275}
                        minHeight={260}
                        hasError={fieldHasError("message")}
                        autoFocus
                    />
                </div>
            </div>
            <div className="ContactsForm__footer">
                <Button
                    className="ContactsForm__footer-button ContactsForm__footer-button--close"
                    onClick={onClose}
                    modifiers={["secondary"]}
                >
                    Cancel
                </Button>

                <Button
                    className="ContactsForm__footer-button ContactsForm__footer-button--submit"
                    onClick={handleSave}
                    modifiers={["primary"]}
                >
                    SUBMIT
                </Button>
            </div>
        </>
    );
};

export default FormUpdate;
