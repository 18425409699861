import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import TabList from "../../../components/new/TabList/TabList";
import DownloadButton from "../../../components/new/DownloadButton/DownloadButton";
import { ChainDetailsDataContext, HEADER_LARGE_HEIGHT, HEADER_SMALL_HEIGHT, } from "../ChainPage.helpers";
import "./ChainsDetailsHeader.styles.scss";
import Button from "../../../components/new/Button/Button";
import { IconClose, IconNotesAdd } from "@icons";
import Dropdown from "../../../components/new/Dropdown/Dropdown";
import Notes from "../../../components/new/Notes/Notes";
import useOutsideClick from "../../../core/hooks/useOutsideClick";
import { getEnding, getFormattedThousand, sanitizeUrl } from "@helpers";
import { PermissionLevelEnum } from "@models/PermissionSection";
import {
    AppId,
    getScaledPixels, PlatformContext,
    useWindowSizeDebounced,
} from "@datassential/platform-ui-lib";

const ChainsDetailsHeader = forwardRef((props, ref: any) => {
    const { userInfo: { products } } = useContext(PlatformContext);
    const {
        data: {
            mainData: data,
            notes,
        },
        sections,
    } = useContext(ChainDetailsDataContext);
    const [wWidth] = useWindowSizeDebounced();

    const {
        id: chainId,
        name,
        logoUrl: logo,
        segmentName: segment,
        cuisinePrimary: cuisine,
        unitsCount,
        company: holdingCompanyName,
    } = data;

    const infoBlockRef = useRef(1);
    const infoDetailsBlockRef = useRef(0.5);
    const notesRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(HEADER_LARGE_HEIGHT);
    const [isShownNotes, setIsShownNotes] = useState(false);
    const [notesCount, setNotesCount] = useState(0);

    const classnames = cn(
        "ChainsDetailsHeader__official-name",
        headerHeight !== HEADER_LARGE_HEIGHT && "is-hidden",
    );

    const handleScroll = useCallback(() => {
        if (ref) {
            const currentTop = ref.current.scrollTop;
            const coefficient = Math.min(
                1,
                currentTop / (HEADER_LARGE_HEIGHT - HEADER_SMALL_HEIGHT),
            );

            if (infoBlockRef.current) {
                infoBlockRef.current = 1 - coefficient / 2;
            }

            if (infoDetailsBlockRef.current) {
                infoDetailsBlockRef.current = Math.max(0.5, Math.ceil(coefficient * 100) / 100);
            }

            const currentHeight =
                HEADER_LARGE_HEIGHT - (HEADER_LARGE_HEIGHT - HEADER_SMALL_HEIGHT) * coefficient;

            if (currentHeight !== headerHeight) {
                setHeaderHeight(currentHeight);
            }
        }
    }, [headerHeight, ref]);

    useEffect(() => {
        const container = ref.current;

        if (container) {
            container.addEventListener("scroll", handleScroll);

            return () => {
                container.removeEventListener("scroll", handleScroll);
            };
        }
    }, [handleScroll, ref]);

    const handleCloseNotes = useCallback(() => setIsShownNotes(false), []);

    useOutsideClick({
        ref: notesRef,
        isDisabled: !isShownNotes,
        fn: handleCloseNotes
    });

    const handleSetNotes = useCallback((itemsCount: number) => {
        setNotesCount(itemsCount);
    }, []);

    const fireflyLink = products[AppId.Operators].url;
    const unitsLink = useMemo(() => {
        return sanitizeUrl(`${fireflyLink}${process.env.REACT_APP_FF_UNITS_PATH}${chainId}`)
    }, [chainId, fireflyLink]);


    return (
        <div className="ChainsDetailsHeader" style={{ height: `${headerHeight}px` }}>
            <div
                className="ChainsDetailsHeader__info-block"
                style={{ transform: `scale(${infoBlockRef.current})` }}
            >
                {logo && (
                    <div className="ChainsDetailsHeader__logo-wrapper">
                        <img className="ChainsDetailsHeader__logo" src={logo} alt="" />
                    </div>
                )}

                <div className="ChainsDetailsHeader__main-info">
                    <div className="ChainsDetailsHeader__chain-name">{name}</div>
                    <div
                        className="ChainsDetailsHeader__details"
                        style={{ fontSize: `${infoDetailsBlockRef.current * 24}px` }}
                    >
                        {!!segment && <span className="ChainsDetailsHeader__sub-title-item">{segment}</span>}
                        {!!cuisine && <span className="ChainsDetailsHeader__sub-title-item">{cuisine}</span>}
                        {!!fireflyLink && (
                            <a
                                className="ChainsDetailsHeader__link"
                                target="_blank"
                                rel="noreferrer"
                                href={unitsLink}
                            >
                                {getFormattedThousand(unitsCount)} {getEnding('unit', unitsCount)}
                            </a>
                        )}
                    </div>
                    <div className={classnames}>{holdingCompanyName}</div>
                </div>
            </div>

            <TabList
                data={sections}
                className="ChainsDetailsHeader__navigation"
                offset={getScaledPixels(70, wWidth) + headerHeight}
            />

            <DownloadButton
                className="ChainsDetailsHeader__download-button"
            >
                Download
            </DownloadButton>

            {notes.permissionLevel !== PermissionLevelEnum.None && (
                <div className="ChainsDetailsHeader__notes-wrapper" ref={notesRef}>
                    <Button
                        modifiers={["accent-gradient"]}
                        className="ChainsDetailsHeader__notes-add"
                        onClick={() => setIsShownNotes(!isShownNotes)}
                    >
                        {isShownNotes && IconNotesClose}
                        {!isShownNotes && IconNotesOpen}
                        {notesCount > 0 && (
                            <div className="ChainsDetailsHeader__notes-add-counter">
                                {notesCount}
                            </div>
                        )}
                    </Button>
                    

                    <div className="ChainsDetailsHeader__notes">
                        <Dropdown
                            shown={isShownNotes}
                            position="inside"
                            unmountOnExit={false}
                        >
                            <Notes
                                isOpen={isShownNotes}
                                onClose={handleCloseNotes}
                                setNotesCount={handleSetNotes}
                            />
                        </Dropdown>
                    </div>
                </div>
            )}
        </div>
    );
});

const IconNotesOpen = <IconNotesAdd />;
const IconNotesClose = <IconClose width={16} height={16} />;

export default React.memo(ChainsDetailsHeader);
