import React, { useEffect, useMemo, useState } from "react";
import useWindowSizeDebounced from "../../../../core/hooks/useWindowSizeDebounced";
import { iContact } from "../../../../core/models/Contact";
import ContactCard from "../../../../components/new/ContactCard/ContactCard";
import EmptyContactCard from "../../../../components/new/EmptyContactCard/EmptyContactCard";
import { ContactsTypeEnum, getSlidesPerView } from "./Contacts.helpers";
import "./ContactsTiles.styles.scss";

interface iProps {
    data: iContact[];
    onAdd: () => void;
    onEdit: (id: number) => void;
    contactsType: ContactsTypeEnum;
}

const ContactsTiles: React.FC<iProps> = ({
    onEdit,
    onAdd,
    data,
    contactsType,
}) => {
    const [wWidth] = useWindowSizeDebounced(1000);
    const isCustom = !(contactsType === ContactsTypeEnum.Firefly);
    const tilesPerView = useMemo(() => getSlidesPerView(wWidth, 215), [wWidth]);
    const [isExpanded, setIsExpanded] = useState(false);
    const [items, setItems] = useState<iContact[]>([]);

    useEffect(() => {
        const totalItems = data.length;
        const end =
            !isExpanded && totalItems >= tilesPerView * 2 ? tilesPerView * 2 - 1 : totalItems;
        const items = data.slice(0, end);
        setItems(items);
    }, [data, isExpanded, tilesPerView]);

    const style = useMemo(
        () =>
            ({
                "--columns": tilesPerView,
            } as React.CSSProperties),
        [tilesPerView],
    );

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="ContactsTiles" style={style}>
            <div className="ContactsTiles__row">
                {items.map(item => (
                    <ContactCard key={item.id} data={item} onEdit={id => onEdit(id)} />
                ))}
                {!!items.length && (
                    <EmptyContactCard
                        onClick={onAdd}
                        text={isCustom ? "Add my contact" : "Request addition"}
                    />
                )}
            </div>

            {data.length >= tilesPerView * 2 && (
                <div className="ContactsTiles__toggle" onClick={handleToggleExpand}>
                    {isExpanded ? "View less" : "View more"}
                </div>
            )}
        </div>
    );
};

export default React.memo(ContactsTiles);
