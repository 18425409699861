import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Button from "../../../../components/new/Button/Button";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import LocationTile from "./LocationTile/LocationTile";
import Table from "../../../../components/new/Table/Table";
import {
    COMPETITORS_CELL_METROS_WIDTH,
    COMPETITORS_CELL_OVERLAP_WIDTH,
    CurrentTabData,
    getClipboardData,
    getTabItems,
    iCurrentTabData,
    iTableConfig,
} from "./Locations.helpers";
import Tabs from "../../../../components/new/Tabs/Tabs";
import TabsItem from "../../../../components/new/Tabs/TabsItem";
import "./Locations.styles.scss";
import TableHead from "../../../../components/new/Table/TableHead";
import { TableContext } from "@components/new/Table/TableContext";
import { CustomBaseScrollbars } from "@components/CustomScrollbarsVirtualList/CustomScrollbarsVirtualList";
import CopyButton, { CopyButtonContext } from "../../../../components/new/Table/CopyButton";
import NewTooltip from "../../../../components/new/NewTooltip/NewTooltip";
import {
    getCompetitorsTableConfig,
    getMetrosTableConfig,
    getPopulationTableConfig,
    getStateTableConfig,
} from "./Locations.configs";
import { ID } from "@models";
import { iTableConfigItem } from "@components/new/Table/Table.helpers";
import useTableSort from "../../../../components/new/Table/useTableSort";
import useLocations from "../../../../core/apiHooks/useLocations";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { ReportsNameEnum } from "@models/ChainDetails";
import { PermissionLevelEnum } from "@models/PermissionSection";
import { Lock, LockDefaultAction } from "@components/new/LockSection/LockSection";
import { ChainDetailsDataContext, ChainSectionsEnum, getSectionData } from "../../ChainPage.helpers";
import CopyNotification from "../../../../components/new/Table/CopyNotification";
import useNotification from "../../../../core/hooks/useNotification";
import { SectionEnum } from "@enums";
import { AppId, ProductLogo } from "@datassential/platform-ui-lib";

const Locations: FC = () => {
    const {
        data: {
            mainData: {
                id: chainId,
                name: chainName,
            },
        },
        downloadReports,
    } = useContext(ChainDetailsDataContext);

    const {
        data: {
            permissionLevel,
            data: {
                terrains,
                residentPopulation,
                competitors,
                marketAreas,
            },
        },
        isLoading,
    } = useLocations(chainId);

    const [selectedTabId, setSelectedTabId] = useState<ID>(1);

    const [currentTabData, setCurrentTabData] = useState<iCurrentTabData>({
        defaultField: "numberOfStores",
        data: marketAreas.stateMarketAreas,
        tableConfigKey: "states"
    });
    const notification = useNotification();

    useEffect(() => {
        switch (selectedTabId) {
            case 1:
                setCurrentTabData({
                    defaultField: "numberOfStores",
                    data: marketAreas.stateMarketAreas,
                    tableConfigKey: "states",
                });
                break;
            case 2:
                setCurrentTabData({
                    defaultField: "numberOfStores",
                    data: marketAreas.metroMarketAreas,
                    tableConfigKey: "metros",
                });
                break;
            case 3:
                setCurrentTabData({
                    defaultField: "commonMetros",
                    data: competitors.items,
                    tableConfigKey: "competitors",
                });
                break;
            case 4:
                setCurrentTabData({
                    defaultField: "percent",
                    data: residentPopulation.statistic.gender,
                    tableConfigKey: "population",
                });
                break;
            default:
                setCurrentTabData({
                    defaultField: "region",
                    data: marketAreas.stateMarketAreas,
                    tableConfigKey: "states",
                });
        }
    }, [
        selectedTabId,
        residentPopulation.statistic.gender,
        marketAreas.metroMarketAreas,
        marketAreas.stateMarketAreas,
        competitors.items,
    ]);

    const {
        handleSort,
        sortingField,
        sortingDirection,
        getColumnIcon,
        sortData,
        sortedData
    } = useTableSort(currentTabData.data, currentTabData.defaultField, "desc");

    const columnIcon = useCallback(
        function (fieldName: keyof CurrentTabData) {
            return getColumnIcon(fieldName, sortingField, sortingDirection)
        },
        [getColumnIcon, sortingDirection, sortingField]
    );

    const getTableConfig = useCallback(
        function (fn: (config: any) => iTableConfigItem[], data: Partial<CurrentTabData>[], chainName: string = "") {
            return fn({
                data: sortData(data, sortingField),
                sortingField,
                columnIcon,
                handleSort,
                chainName
            })
        },
        [columnIcon, handleSort, sortData, sortingField],
    );

    // TODO: simplify tableConfigs to separate variables
    const tableConfigs: iTableConfig = useMemo(() => {
        return {
            states: getTableConfig(getStateTableConfig, marketAreas.stateMarketAreas),
            metros: getTableConfig(getMetrosTableConfig, marketAreas.metroMarketAreas),
            competitors: getTableConfig(getCompetitorsTableConfig, competitors.items),
            population: {
                gender: getTableConfig(getPopulationTableConfig, residentPopulation.statistic.gender, chainName),
                ethnicity: getTableConfig(getPopulationTableConfig, residentPopulation.statistic.ethnicity),
                languages: getTableConfig(getPopulationTableConfig, residentPopulation.statistic.languages),
                generations: getTableConfig(getPopulationTableConfig, residentPopulation.statistic.generations),
                birthPlace: getTableConfig(getPopulationTableConfig, residentPopulation.statistic.birthPlace),
            },
        };
    }, [
        getTableConfig,
        marketAreas.stateMarketAreas,
        marketAreas.metroMarketAreas,
        competitors.items,
        residentPopulation.statistic.gender,
        residentPopulation.statistic.ethnicity,
        residentPopulation.statistic.languages,
        residentPopulation.statistic.generations,
        residentPopulation.statistic.birthPlace,
        chainName,
    ]);

    const populationTables = useMemo(() => {
        const { statistic } = residentPopulation;
        const { gender, ethnicity, languages, generations, birthPlace } = tableConfigs.population;
        return [
            {
                label: "Gender",
                config: gender,
                rowsCount: statistic.gender.length,
            },
            {
                label: "Ethnicity",
                config: ethnicity,
                rowsCount: statistic.ethnicity.length,
            },
            {
                label: "Languages",
                config: languages,
                rowsCount: statistic.languages.length,
            },
            {
                label: "Generations",
                config: generations,
                rowsCount: statistic.generations.length,
            },
            {
                label: "Birthplace",
                config: birthPlace,
                rowsCount: statistic.birthPlace.length,
            },
        ];
    }, [residentPopulation, tableConfigs.population]);

    const handleChangeTab = (id: ID) => setSelectedTabId(id);

    const isDownloadShow = (permissionLevel === PermissionLevelEnum.Full) && !isLoading;

    const clipboardData = useMemo(
        () =>
            getClipboardData({
                tabData: currentTabData,
                tableConfigs,
                sortedData,
                population: residentPopulation.statistic,
            }),
        [currentTabData, residentPopulation.statistic, sortedData, tableConfigs],
    );

    return (
        <div className="Locations">
            <SectionHeader title="Locations">
                {isDownloadShow && (
                    <div className="Locations__actions-item">
                        <Button modifiers={["primary"]} onClick={() => downloadReports([ReportsNameEnum.Firefly])}>
                            DOWNLOAD LOCATION REPORT
                        </Button>
                    </div>
                )}
            </SectionHeader>

            <SectionContent isLoading={isLoading}>
                <div className="Locations__content">
                    <div className="Locations__tiles">
                        {terrains.map(el => {
                            return (
                                <div className="Locations__tile" key={el.name}>
                                    <LocationTile data={el}/>
                                </div>
                            );
                        })}
                    </div>

                    <div className="Locations__tabs">
                        <CopyButtonContext.Provider value={{ data: clipboardData }}>
                        <Tabs
                            items={getTabItems({
                                marketAreas,
                                competitors,
                                residentPopulation,
                                terrains,
                            })}
                            selectedId={selectedTabId}
                            onChange={handleChangeTab}
                        >
                            <TabsItem isShown={selectedTabId === 1}>
                                <div className="Locations__tab">
                                    <Table
                                        config={tableConfigs.states}
                                        rowsCount={marketAreas.stateMarketAreas.length}
                                    />
                                </div>
                            </TabsItem>
                            <TabsItem isShown={selectedTabId === 2}>
                                <div className="Locations__tab">
                                    <Table
                                        config={tableConfigs.metros}
                                        rowsCount={marketAreas.metroMarketAreas.length}
                                    />
                                </div>
                            </TabsItem>
                            <TabsItem isShown={selectedTabId === 3}>
                                <div className="Locations__tab">
                                    <div className="Locations__competitors">
                                        <div className="Locations__competitors-sub-header">
                                            <div
                                                className="Locations__competitors-sub-header-item is-highlight"
                                                style={{ width: COMPETITORS_CELL_METROS_WIDTH * 2 }}
                                            >
                                                COMMON METROS
                                            </div>
                                            <div
                                                className="Locations__competitors-sub-header-item is-hoverable"
                                                style={{
                                                    width: COMPETITORS_CELL_OVERLAP_WIDTH * 3,
                                                }}
                                            >
                                                NATIONAL OVERLAP
                                                <NewTooltip className="Locations__tab-tooltip">
                                                    % of stores within miles of that other chain
                                                </NewTooltip>
                                            </div>
                                        </div>
                                        <div className="Locations__competitors-table">
                                            <Table
                                                config={tableConfigs.competitors}
                                                rowsCount={competitors.items.length}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </TabsItem>

                            <TabsItem isShown={selectedTabId === 4}>
                                <div className="Locations__tab">
                                    <div className="Locations__tables-group">
                                        <TableContext.Provider
                                            value={{ config: tableConfigs.population.gender }}
                                        >
                                            <div className="Locations__tables-group-head">
                                                <TableHead/>
                                            </div>
                                        </TableContext.Provider>

                                        <div
                                            className="Locations__tables-group-copy"
                                            onClick={notification.handleShowNotification}
                                        >
                                            <CopyButton/>
                                        </div>
                                        <div className="Locations__tables-wrapper">
                                            <CustomBaseScrollbars>
                                                {populationTables.map(
                                                    ({ label, config, rowsCount }) => {
                                                        return (
                                                            <div
                                                                className="Locations__tables-group-item"
                                                                key={label}
                                                            >
                                                                <div className="Locations__table-sub-header">
                                                                    {label}
                                                                </div>
                                                                <Table
                                                                    config={config}
                                                                    rowsCount={rowsCount}
                                                                    hasHeader={false}
                                                                    hasInnerScroll={false}
                                                                    isCopyButtonHidden
                                                                />
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </CustomBaseScrollbars>
                                            <CopyNotification isOpen={notification.isOpen}/>
                                        </div>
                                    </div>
                                </div>
                            </TabsItem>
                        </Tabs>
                        </CopyButtonContext.Provider>
                        <div className="Locations__tabs-logo">
                            <ProductLogo
                                type="regular"
                                appId={AppId.Chains}
                                className="Locations__tabs-logo-icon"
                            />
                        </div>
                    </div>
                    {permissionLevel === PermissionLevelEnum.None && (
                        <Lock>
                            {getSectionData(ChainSectionsEnum.Locations).noAccessMessage}
                            <LockDefaultAction section={SectionEnum.Location}/>
                        </Lock>
                    )}
                </div>
            </SectionContent>
        </div>
    );
};

export default React.memo(Locations);
