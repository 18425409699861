import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import cn from "classnames";
import useContactsData from "./useContactsData";
import { iContact } from "@models/Contact";
import SectionHeader from "../../../../components/new/SectionHeader/SectionHeader";
import FormRequestContact from "../../../../components/new/ContactForms/FormRequestContact/FormRequestContact";
import { ContactsTypeEnum, iUpdateRequest, ViewTypeEnum } from "./Contacts.helpers";
import FormCustomContact from "../../../../components/new/ContactForms/FormAddContact/FormCustomContact";
import useQueryParam from "../../../../core/hooks/useQueryParam";
import "./Contacts.styles.scss";
import { ChainDetailsDataContext, ChainSectionsEnum, getSectionData } from "../../ChainPage.helpers";
import { PermissionLevelEnum } from "@models/PermissionSection";
import FormToggle from "../../../../components/new/FormToggle/FormToggle";
import ContactsTiles from "./ContactsTiles";
import ContactsList from "./ContactsList";
import SectionContent from "../../../../components/new/SectionContent/SetionContent";
import { Lock, LockDefaultAction } from "@components/new/LockSection/LockSection";
import { SectionEnum } from "@enums";
import ContactAddButton from "./ContactAddButton/ContactAddButton";
import { AppId, usePlatformContext } from "@datassential/platform-ui-lib";

const ANIM_DURATION_CLOSE_POPUP = 100;

const Contacts: FC = () => {
    const {
        data: {
            mainData: {
                logoUrl: chainLogo,
            }
        }
    } = useContext(ChainDetailsDataContext);

    const {
        userInfo: { products },
    } = usePlatformContext();

    const product = useMemo(
        () => products[AppId.Chains],
    [products]
    );

    const [searchQuery, setSearchQuery] = useState("");
    const [view, setView] = useState<ViewTypeEnum>(ViewTypeEnum.List);
    const chainId = useQueryParam("id");

    const CURRENT_CONTACT_INFO = useMemo(
        () => ({
            chainLogo,
            chainId,
            searchQuery,
        }),
        [chainId, chainLogo, searchQuery],
    );

    const {
        isLoading,
        saveContact,
        removeContact,
        customContacts,
        fireflyContacts,
        requestForAdd,
        requestForChange,
        fireflyContactsPermissionLevel,
        customContactsPermissionLevel,
    } = useContactsData(CURRENT_CONTACT_INFO);

    const [isShownRequestPopup, setIsShownRequestPopup] = useState(false);
    const [isAddRequest, setIsAddRequest] = useState(true);
    const [selectedContact, setSelectedContact] = useState<iContact | null>(null);
    const [isShownCustomPopup, setIsShownCustomPopup] = useState(false);
    const [isShownOnlyMyContacts, setIsShownOnlyMyContacts] = useState(false);

    const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchQuery(value);
    };

    // Custom contact handlers
    const handleCloseCustomPopup = () => {
        setIsShownCustomPopup(false);
        setTimeout(() => {
            setSelectedContact(null);
        }, ANIM_DURATION_CLOSE_POPUP);
    };

    const handleSaveCustomContact = (item: iContact) => {
        saveContact(item);
        handleCloseCustomPopup();
    };

    const handleRemoveCustomContact = (id: number) => {
        removeContact(id);
        handleCloseCustomPopup();
    };

    // Firefly contact handlers
    const handleResetRequestPopup = () => {

        setTimeout(() => {
            setIsAddRequest(true);
            setSelectedContact(null);
        }, ANIM_DURATION_CLOSE_POPUP);

    };

    const handleAddRequestContact = useCallback((item: iContact) => {
        requestForAdd(item);
        setIsShownRequestPopup(!isShownRequestPopup);
        handleResetRequestPopup();
    }, [isShownRequestPopup, requestForAdd]);

    const handleUpdateRequestContact = useCallback((data: iUpdateRequest) => {
        const { message, contact } = data;
        requestForChange({ item: contact, message });
        setIsShownRequestPopup(!isShownRequestPopup);
        handleResetRequestPopup();
    }, [isShownRequestPopup, requestForChange]);

    const handleToggleRequestPopup = useCallback(() => {
        setIsShownRequestPopup(!isShownRequestPopup);
        handleResetRequestPopup();
    }, [isShownRequestPopup]);

    const handleOpenUpdateRequestPopup = (id: number) => {
        const contact = fireflyContacts.find(item => item.id === id);
        if (contact) {
            setSelectedContact(contact);
            setIsShownRequestPopup(!isShownRequestPopup);
            setIsAddRequest(false);
        }
    };

    const handleOpenEditCustomPopup = (id: number) => {
        const contact = customContacts.find(item => item.id === id);
        if (contact) {
            setSelectedContact(contact);
            setIsShownCustomPopup(true);
        }
    };


    const filteredCustomContacts = useMemo(
        () => customContacts.filter(item => (isShownOnlyMyContacts ? item.canEdit : item)),
        [customContacts, isShownOnlyMyContacts],
    );

    const countMyContacts = useMemo(() => customContacts.filter(item => item.canEdit).length ,[customContacts])

    useEffect(() => {
        if ( customContacts.length > 0 && filteredCustomContacts.length === 0 ) {
            setIsShownOnlyMyContacts(false);
        }
    }, [customContacts, filteredCustomContacts]);

    const sectionStyles = useMemo(() => {
        return cn(!isLoading && !filteredCustomContacts.length && !fireflyContacts.length && "is-compact")
    }, [filteredCustomContacts.length, fireflyContacts.length, isLoading]);

    return (
        <div className="Contacts">
            <SectionHeader title="Contacts">
                <div className="Contacts__actions-item">
                    <input
                        type="text"
                        className="Contacts__search-input"
                        placeholder="Search contact"
                        value={searchQuery}
                        onChange={handleChangeSearch}
                        spellCheck={false}
                    />
                </div>
                {/*<div className="Contacts__actions-item">*/}
                {/*    <div className="Contacts__view">*/}
                {/*        <ButtonView*/}
                {/*            selectedView={view}*/}
                {/*            type={ViewTypeEnum.Tiles}*/}
                {/*            onClick={handleChangeView}*/}
                {/*        />*/}
                {/*        <ButtonView*/}
                {/*            selectedView={view}*/}
                {/*            type={ViewTypeEnum.List}*/}
                {/*            onClick={handleChangeView}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </SectionHeader>

            <SectionContent
                isLoading={isLoading}
                className={sectionStyles}
            >
                {customContactsPermissionLevel !== PermissionLevelEnum.None && (
                    <div className="Contacts__section">
                        <div className="Contacts__section-header">
                            <div className="Contacts__section-title">My contacts</div>
                            <div className="Contacts__header-actions">
                                {countMyContacts > 0 && (
                                    <FormToggle
                                        labels={["", "show only my contacts"]}
                                        isChecked={isShownOnlyMyContacts}
                                        onChange={() => setIsShownOnlyMyContacts(!isShownOnlyMyContacts)}
                                    />
                                )}
                                {filteredCustomContacts.length === 0 && view === ViewTypeEnum.Tiles && (
                                    <div className="Contacts__no-data">
                                        <div className="Contacts__no-data-message">
                                            There are no contacts yet. You can create the first one
                                        </div>
                                    </div>
                                )}
                                <div className="Contacts__header-add">
                                    {!filteredCustomContacts.length && view === ViewTypeEnum.Tiles && (
                                        <ContactAddButton
                                            text="Add my contact"
                                            onClick={() => setIsShownCustomPopup(true)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                            <div className={cn(
                                    "Contacts__items",
                                    {
                                        "is-empty": filteredCustomContacts.length === 0 && view === ViewTypeEnum.Tiles
                                    }
                                )}
                            >
                                {view === ViewTypeEnum.Tiles && filteredCustomContacts.length > 0 && (
                                    <ContactsTiles
                                        data={filteredCustomContacts}
                                        onAdd={() => setIsShownCustomPopup(true)}
                                        onEdit={handleOpenEditCustomPopup}
                                        contactsType={ContactsTypeEnum.Custom}
                                    />
                                )}

                                {view === ViewTypeEnum.List && (
                                    <ContactsList
                                        searchQuery={searchQuery}
                                        data={filteredCustomContacts}
                                        onEdit={handleOpenEditCustomPopup}
                                        onAdd={() => setIsShownCustomPopup(true)}
                                        chainLogo={chainLogo}
                                        contactsType={ContactsTypeEnum.Custom}
                                    />
                                )}
                            </div>

                    </div>
                )}

                <div className={cn(
                            "Contacts__section",
                            {
                                "is-empty": fireflyContacts.length === 0
                            }
                        )}
                >
                    <div className="Contacts__section-header">
                        <div className="Contacts__section-title">
                            {product.name} contacts
                        </div>
                        <div className="Contacts__header-actions">
                            {fireflyContacts.length === 0 && view === ViewTypeEnum.Tiles && (
                                <div className="Contacts__no-data">
                                    <div className="Contacts__no-data-message">
                                        There are no contacts yet. You can send request to create the first one
                                    </div>
                                </div>
                            )}
                            <div className="Contacts__header-add">
                                {!fireflyContacts.length && view === ViewTypeEnum.Tiles && (fireflyContactsPermissionLevel !== PermissionLevelEnum.None) && (
                                    <ContactAddButton
                                        text="Request for add contact"
                                        onClick={handleToggleRequestPopup}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={cn(
                            "Contacts__items",
                            {
                                "is-empty": fireflyContacts.length === 0 && view === ViewTypeEnum.Tiles
                            }
                        )}
                    >
                        {view === ViewTypeEnum.Tiles && (
                            <ContactsTiles
                                data={fireflyContacts}
                                onEdit={handleOpenUpdateRequestPopup}
                                onAdd={handleToggleRequestPopup}
                                contactsType={ContactsTypeEnum.Firefly}
                            />
                        )}

                        {view === ViewTypeEnum.List && (
                            <ContactsList
                                searchQuery={searchQuery}
                                data={fireflyContacts}
                                onEdit={handleOpenUpdateRequestPopup}
                                onAdd={handleToggleRequestPopup}
                                chainLogo={chainLogo}
                                contactsType={ContactsTypeEnum.Firefly}
                            />
                        )}
                        {fireflyContactsPermissionLevel === PermissionLevelEnum.None && (
                            <Lock>
                                {getSectionData(ChainSectionsEnum.Contacts).noAccessMessage}
                                <LockDefaultAction section={SectionEnum.Contacts}/>
                            </Lock>
                        )}
                    </div>
                </div>
            </SectionContent>

            <FormCustomContact
                contact={selectedContact}
                isShown={isShownCustomPopup}
                onClose={handleCloseCustomPopup}
                onRemove={handleRemoveCustomContact}
                onSave={handleSaveCustomContact}
            />
            <FormRequestContact
                contact={selectedContact}
                isShown={isShownRequestPopup}
                onClose={handleToggleRequestPopup}
                onAdd={handleAddRequestContact}
                onUpdate={handleUpdateRequestContact}
                isAdd={isAddRequest}
            />
        </div>
    );
};

export default React.memo(Contacts);
