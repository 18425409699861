import { FC, useEffect, useState } from "react";
import { Contact, iContact } from "@models/Contact";
import { iUpdateRequest } from "@pages/ChainPage/Sections/Contacts/Contacts.helpers";
import ContactsForm from "../ContactsForm";
import FormAdd from "./SubForms/FormAdd";
import FormUpdate from "./SubForms/FormUpdate";

interface iProps {
    isShown: boolean;
    isAdd?: boolean;
    onClose: () => void;
    onAdd: (item: iContact) => void;
    onUpdate: (item: iUpdateRequest) => void;
    contact: iContact | null;
}

const FormRequestContact: FC<iProps> = ({
    isShown,
    isAdd = true,
    onClose,
    onAdd,
    onUpdate,
    contact,
}) => {
    const [isAdding, setIsAdding] = useState<boolean>(isAdd);
    const [data, setData] = useState<iContact>(new Contact());

    const resetForm = () => {
        setData(new Contact());
    };

    useEffect(() => {
        setIsAdding(isAdd);
        resetForm();
    }, [isShown, isAdd]);

    const handleInputChange = (fieldName: keyof iContact) => (value: string) => {
        setData({ ...data, [fieldName]: value });
    };

    return (
        <ContactsForm isShown={isShown} onClose={onClose}>
            <div className="ContactsForm__header">
                <div className="ContactsForm__header-title">{isAdding ? "Request addition" : "Request update"}</div>
            </div>
            <FormAdd data={data} isShown={isAdding} onChange={handleInputChange} onClose={onClose} onSubmit={onAdd} />
            <FormUpdate isShown={!isAdding} onClose={onClose} onSubmit={onUpdate} contact={contact} />
        </ContactsForm>
    );
};

export default FormRequestContact;
